import {plugin_registry, PluginBase} from "nk-plugin-registry"


@plugin_registry.register('ListFilter')
class ListFiler extends PluginBase {
  constructor($node) {
    super($node);

    this.$selects = $node.querySelectorAll('select');
    this.$filter_items = []
    this.filter_items_map = new Map()
  }

  get filter_state() {
    return [...this.$selects]
      .reduce(
        (a, $s) => {
          a[$s.getAttribute('name')] = $s.value.length ? $s.value : undefined;
          return a
        },
        {},
      )
  }

  loaded($node) {
    super.loaded($node);
    this.$filter_items = [...document.querySelectorAll('[data-filter]')];

    // parse json only once and cache the results
    for (const $filter_item of this.$filter_items) {
      this.filter_items_map.set($filter_item, JSON.parse($filter_item.dataset['filter']))
    }

    this._install_event_listeners();
  }

  _install_event_listeners = () => {
    this.$selects.forEach(
      ($s) => $s.addEventListener('change', this._changed_select($s)),
    )
  }

  _changed_select = ($select) => (event) => {
    this._apply_filter_state(this.filter_state)
  }

  _apply_filter_state = (filter_state) => {
    let hide_on_filter = false;
    for (const $filter_item of this.$filter_items) {
      $filter_item.style['display'] = 'none';
      // TODO: use arias here to mark the filter_item hidden and visible

      let should_show = true;
      for (const filter_key of Object.keys(filter_state)) {
        const filter_value = filter_state[filter_key];
        const filter_item_data = this.filter_items_map.get($filter_item)

        if (filter_value) {
          if (filter_item_data[filter_key].indexOf(filter_value) < 0) {
            should_show = should_show && false;
          }
        }
      }

      if (should_show) {
        $filter_item.style['display'] = 'block';
      }
      hide_on_filter = hide_on_filter || !should_show;
    }
    if (hide_on_filter) {
      document.querySelectorAll('[data-js-select="hide-on-filter"]')
          .forEach(($elem)=> $elem.style["display"]="none")
    } else {
      document.querySelectorAll('[data-js-select="hide-on-filter"]')
          .forEach(($elem)=> $elem.style["display"]="block")
    }
  }
}
