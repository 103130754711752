import "scss/index.scss";

import { plugin_registry } from 'nk-plugin-registry';

import "generics/inpagenavigation";
import "generics/scrollToTopButton";

// import your plugins here.

import "header/js/header.js";
import "footer/js/footer.js";
import "image/js/image.js";
import "extendable/js/extendable.js";
import "stage/js/stage.js";
import "youtube_video/js/youtube_video.js";

import "../../members/js/list_filters.js";
import "../../events/js/database_list_filters.js"

plugin_registry.init();
