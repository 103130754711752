import {plugin_registry, PluginBase} from "nk-plugin-registry";

import "../scss/youtube_video.scss";

const youtube_video_instances = new Map();

@plugin_registry.register('YouTubeVideo')
class YouTubeVideo extends PluginBase {
  constructor($node) {
    super($node);
    this._$node = $node;
  }

  connect($node) {
    super.connect($node);
    this._$node = $node;
    youtube_video_instances.set(this._$node, this);
  }

  loaded($node) {
    super.loaded($node);
    this._$video_overlay = this._$node.querySelector('.consent-overlay');
    this._$video_overlay_activate_button = this._$node.querySelector('[data-js-select="activate-button"]');
    this._$video = this._$node.querySelector('[data-js-select="youtube-embed"]');

    this._$video_overlay_activate_button.addEventListener('click', this._activate_all)
  }

  disconnect($node) {
    super.disconnect($node);
    this._$node = null;
    this._$video_overlay_activate_button = null;
    this._$video_overlay = null;
    youtube_video_instances.delete(this._$node);
  }

  activate = () => {

    if (this._$video_overlay) {
      this._$video_overlay.classList.add('hide');
    }
    this._$video.classList.remove('hide');
    const video_url = this._$video.dataset['src'];
    this._$video.setAttribute('src', video_url);
    if (this._$video_overlay_activate_button) {
      this._$video_overlay_activate_button.removeEventListener('click', this._activate);
      this._$video_overlay_activate_button = null;
    }
  }

  _activate_all = (event) => {
    this.activate();

    // activate all other video players
    for (const [$node, instance] of youtube_video_instances) {
      if (this._$node !== $node) {
        instance.activate()
      }
    }
  }
}
