import "../scss/footer.scss";
import {plugin_registry, PluginBase} from "nk-plugin-registry";


@plugin_registry.register("Footer")
class Footer extends PluginBase {
  constructor($node) {
    super($node);
  }
}

export { Footer }
