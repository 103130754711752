import "../scss/extendable.scss"

import {plugin_registry, PluginBase} from "nk-plugin-registry"

@plugin_registry.register('ExtendableExtender')
class ExtendableExtender extends PluginBase {
    constructor($node) {
        super($node);

        this.$clickableHeader = $node.querySelector('[data-js-select=header]')
        this.$chevronUp = $node.querySelector('[data-js-select=chevron-up]');
        this.$chevronDown = $node.querySelector('[data-js-select=chevron-down]');
        this.$extraContent = $node.querySelector('[data-js-select=hide-on-toggle]')
    }

    loaded($node) {
        super.loaded($node);

        this._install_event_listeners();
    }

    _install_event_listeners = () => {

        console.log(676767, this.$chevronDown)

        this.$clickableHeader.addEventListener('click', this._toggle_visibility())

    }

    _toggle_visibility = ($toggleChevron) => (e) => {

        if (this.$extraContent.classList.contains("hidden")) {
            this.$extraContent.classList.remove("hidden");
            this.$chevronUp.classList.remove("hidden")
            this.$chevronDown.classList.add("hidden")
        } else {
            this.$extraContent.classList.add("hidden");
            this.$chevronDown.classList.remove("hidden")
            this.$chevronUp.classList.add("hidden")
        }
    }
}