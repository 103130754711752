import { plugin_registry, PluginBase } from "nk-plugin-registry"


@plugin_registry.register('ScrollToTopButton')
class ScrollToTopButton extends PluginBase {
    constructor($node) {
        super($node);
        this.$node = $node;
        this.$button = $node.querySelector('[data-js-select="scroll-to-top-button"]');
        this.$windowHeight = window.innerHeight;
    }

    loaded($node) {
        super.loaded($node);
        this._install_event_listeners();
    }

    _install_event_listeners = () => {

        window.addEventListener('scroll', () => this._makeScrollButtonVisible())

        this.$button.addEventListener('click', () => this._scrollToTop())

    }
    _makeScrollButtonVisible = () => {

        if (document.body.scrollTop > 1.25 * this.$windowHeight || document.documentElement.scrollTop > 1.25 * this.$windowHeight) {
            this.$button.style.display = "block";
        } else {
            this.$button.style.display = "none";
        }
    }

    _scrollToTop = () => {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }
}

export { ScrollToTopButton }
